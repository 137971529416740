import { defineStore } from 'pinia';

import { getUserContactData } from '@/api/user/userApi';
import type { Address, PhoneNumber } from "@/api/user/types";
import { throwableCall } from "@/api/lib/integration";

export type UserContactsAndAccountStore = {
  mainAddress: Address | null
  phone: PhoneNumber | null,
  mobile: PhoneNumber | null,
  pwEmail: string | null,
};

export const useUserContactsAndAccountStore = defineStore('userContactsDataStore', {
  state: (): UserContactsAndAccountStore => ({
    mainAddress: null,
    phone: null,
    mobile: null,
    pwEmail: null,
  }),

  actions: {
    async fetchUserContacts() {
      const data = await throwableCall(getUserContactData());
      this.phone = data.phone;
      this.mobile = data.mobile;
      this.mainAddress = data.mainAddress;
      this.pwEmail = data.pwEmail;
    },
  },
});

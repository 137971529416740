import { captureException } from '@sentry/vue';
import { defineStore } from 'pinia';

import { getContracts, getDepots } from '@/api/contracts/contractsApi';
import type { Contract, Depot, NormalizedDepot, Status } from '@/api/contracts/types';
import { isAfter, isBefore } from 'date-fns';
import { resolveRelations } from '@/application/utils/api/resolveRelations';
import { deepFreeze } from '@/application/utils/deepFreeze';
import type { NestedContracts, NestedDepots, StringMap } from "@/contracts/types/types";
import { call } from "@/api/lib/integration";
import { FetchError } from "@/api/lib/errors";
import { spartenMapper } from '@/application/utils/sparteFormatter';

export type ContractStore = {
  contractsInitialized: boolean,
  depotsInitialized: boolean,
  contracts: Contract[],
  contractsLoading: boolean,
  contractsError: FetchError | null,
  depots: Depot[],
  depotsLoading: boolean,
  depotsError: FetchError | null,
  statuses: StringMap,
  statusColors: StringMap,
  lastContractsFetch: Date | null,
  lastDepotsFetch: Date | null,
};

export const useContractStore = defineStore('contract', {

  state: (): ContractStore => ({
    contractsInitialized: false,
    depotsInitialized: false,
    statuses: {},
    statusColors: Object.freeze({
      aktiv: 'bg-green-700',
      beitragsfrei: 'bg-blue-300',
      ruhend: 'bg-gray-300',
    }),
    contracts: [],
    contractsLoading: false,
    contractsError: null,
    depots: [],
    depotsLoading: false,
    depotsError: null,
    lastContractsFetch: null,
    lastDepotsFetch: null,
  }),

  getters: {
    getStatusIdByName: (state) => Object.freeze(Object.fromEntries(
      Object.entries(state.statuses).map(([id, name]) => [(name as string).toLowerCase(), Number.parseInt(id, 10)]),
    )),
    getStatusColor: (state) => ({ name }: Status) => state.statusColors[name.toLowerCase()] || 'bg-muted',
    totalCostPerMonth: (state) => state.contracts
      .filter((contract) => {
        const { start, end } = contract.term ?? {};

        return !((start && isAfter(new Date(start), new Date()))
          || (end && isBefore(new Date(end), new Date())));
      })
      .reduce((accumulator, { owner, status, payment }) => (
        owner === null && status.name.toLowerCase() === 'aktiv'
          ? accumulator + (payment?.monatlich || 0)
          : accumulator
      ), 0),
    contractsMap: (state) => new Map(state.contracts.map((contract) => [contract.id, contract])),
  },

  actions: {

    isOutdated (lastFetch: Date | null, minutes: number) {
      if (!lastFetch) {
        return true;
      }
      const now = new Date();
      const diffInMinutes = (now.getTime() - lastFetch.getTime()) / (1000 * 60);
      return diffInMinutes > minutes;
    },

    findDepot (contractId: number): Depot | undefined
    {
      return this.depots.find(depot => depot.id === contractId)
    },

    findContract (contractId: number): Contract | undefined
    {
      return this.contracts.find(contract => contract.id === contractId)
    },

    removeUserContract(userContractId: number) {
      const index = this.contracts.findIndex((contract) => contract.userContractId === userContractId);
      if (index < 0) return;
      this.contracts.splice(index, 1);
    },

    addStatuses(statuses: Status[]) {
      this.statuses = Object.freeze(statuses.reduce(
        (accumulator, { id, name }) => ({ ...accumulator, [id]: name }),
        { ...this.statuses },
      ));
    },

    async fetchContracts(force: boolean = false, minutes: number = 5) {

      if (this.contractsInitialized && !force && !this.isOutdated(this.lastContractsFetch, minutes)) {
        return;
      }

      this.contractsLoading = true;
      this.contractsError = null;
      await call(
        getContracts(),
        (data) => {
          const {
            contracts = [],
            statuses = [],
          } = resolveRelations(data, { type: 'documentTypes' }) as NestedContracts;
          this.contracts = contracts.map((contract) => {
            contract.spartenName = spartenMapper(contract.product)
            if (Array.isArray(contract.meta)) {
              contract.meta = null
            }
            return contract
          });
          this.addStatuses(statuses);
        },
        (error) => {
          if (error instanceof FetchError) {
            this.contractsError = error;
          }
          captureException(error);
        },
      );

      this.contractsInitialized = true;
      this.contractsLoading = false;
      this.lastContractsFetch = new Date();
    },

    async fetchDepots(force: boolean = false, minutes: number = 5) {

      if (this.depotsInitialized && !force && !this.isOutdated(this.lastDepotsFetch, minutes)) {
        return;
      }

      this.depotsError = null;
      this.depotsLoading = true;
      await call(
        getDepots(),
        (data) => {

          const fondFinanzDepots = data.fondFinanzDepots.reduce((acc: Pick<NormalizedDepot, "depot_evaluation" | "depotFonds" | "id" | "monthly_payment_rate">[], depot) => {
            const contract = this.findContract(depot.id)

            if (contract) {
              const merged = { ...depot, ...contract }
              merged.company = contract.company
              acc.push(merged)
            }
            return acc

          }, [] as NormalizedDepot[]) as NormalizedDepot[]
          data.depots = [...data.depots ?? [], ...fondFinanzDepots]

          const { depots = [], statuses = [] } = resolveRelations(data) as NestedDepots;
          this.depots = depots.map((depot) => deepFreeze(depot));

          this.addStatuses(statuses);
        },
        (error) => {
          if (error instanceof FetchError) {
            this.depotsError = error;
          }
          captureException(error);
        },
      );
      this.depotsLoading = false;
      this.depotsInitialized = true
      this.lastDepotsFetch = new Date();
    },

    async fetchContractsAndDepots(force: boolean = false, minutes: number = 5) {
      await this.fetchContracts(force, minutes)
      await this.fetchDepots(force, minutes)
    },
  },

});

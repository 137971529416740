<template>
  <div class="flex flex-col">
    <div
      v-if="validFiles.length > 0"
      class="-mx-4 sm:-mx-6 -mt-4 sm:-mt-6 z-10"
    >
      <h3 class="text-lg text-left font-bold text-gray-900 pb-4 px-4 sm:px-6 pt-4 shadow mb-0">
        Neues Dokument hochladen
      </h3>
    </div>
    <div
      class="flex flex-col -mx-4 sm:-mx-6 overflow-y-auto px-4 sm:px-6"
      :class="validFiles.length > 0 ? 'py-5' : 'pb-5'"
    >
      <FileInput
        :accept="validFileTypes.join(', ')"
        @input="setFiles"
      >
        <DocTypesHint />
      </FileInput>

      <InvalidFilesHint
        :files="invalidFiles"
        :error-messages="errorMessages"
      />

      <div
        v-if="validFiles.length > 0"
        class="flex flex-col gap-5 items-center w-full"
      >
        <DocumentList
          :files="validFiles"
          :file-names="fileNames"
          :disable-image-inputs="metaData.combine"
          class="my-2.5"
          @set-file-name="setFileName"
          @remove="remove"
        />

        <DsFormGroup
          v-if="hasMultipleImageFiles"
          label="Bilder zu einer PDF-Datei kombinieren"
          label-for="combine-images-check"
          inline
        >
          <DsCheckbox
            id="combine-images-check"
            v-model="metaData.combine"
            :disabled="exceedsImageCombinationLimit"
          />
        </DsFormGroup>

        <ImageCombinationHint
          v-if="exceedsImageCombinationLimit"
          class="ml-1.5"
        />

        <MetaDataInput
          v-model:contract-id="metaData.contract_id"
          v-model:document-type-id="metaData.document_type_id"
          v-model:insurance-company-id="metaData.insurance_company_id"
          v-model:product-combo-id="metaData.product_combo_id"
          v-model:note="metaData.note"
          :contracts="contractOptions"
          :doc-types="documentTypeOptions"
          :companies="companyOptions"
          :sparten="sparten"
          :loading="contractsLoading"
        />

        <div
          v-if="hasError"
          class="my-2.5 text-danger text-center"
        >
          Es gab einen Fehler beim Hochladen
          {{ validFiles.length > 1 ? 'der Dokumente' : 'des Dokuments' }}
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-center sm:justify-end gap-3 bg-white -mx-4 pt-4 px-4">
      <DsButton
        variant="secondary"
        class="w-full sm:w-auto"
        size="lg"
        @click="() => {
          $emit('close')
          clear()
        }"
      >
        Abbrechen
      </DsButton>
      <DsButton
        v-if="validFiles.length > 0"
        variant="primary"
        class="w-full sm:w-auto"
        :disabled="!validFiles || loading"
        size="lg"
        :handler="uploadHandler"
      >
        Hochladen
      </DsButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DsButton, DsCheckbox, DsFormGroup } from '@demvsystems/design-components';
import { captureException } from '@sentry/vue';
import { storeToRefs } from 'pinia';
import { reactive, ref, watch } from 'vue';

import { useContractOptions } from '@/contracts/composables/contractOptions';
import { useGesellschaften } from '@/application/composables/gesellschaften';
import { useSparten } from '@/documents/composables/sparten';
import { toOptions } from '@/application/composables/vselect';
import { useContractStore } from '@/contracts/stores/contractStore';
import { useDocumentStore } from '@/documents/store/documentStore';
import { trackEvent } from '@/application/utils/matomo';
import { TrackEventCategory } from '@/application/utils/matomo/events';

import DocTypesHint from './DocTypesHint.vue';
import FileInput from './FileInput.vue';
import DocumentList from './FileList.vue';
import ImageCombinationHint from './ImageCombinationHint.vue';
import InvalidFilesHint from './InvalidFilesHint.vue';
import MetaDataInput from './MetaDataInput.vue';
import type { MetaData } from '../types/types';
import { useDocumentTypes } from "@/documents/composables/documentTypes";
import { useDocumentUpload } from "@/documents/composables/documentUpload";

defineEmits(['close']);

const initMetaData: MetaData = {
  contract_id: null,
  insurance_company_id: null,
  product_combo_id: null,
  document_type_id: null,
  note: null,
  combine: false,
};

const metaData = reactive({ ...initMetaData });
const hasError = ref(false);
const loading = ref(false);

const { fetchDocuments } = useDocumentStore();
const { fetchContracts } = useContractStore();
const { contracts, contractsLoading } = storeToRefs(useContractStore());
const { contractOptions } = useContractOptions();

const { gesellschaften, fetchGesellschaften } = useGesellschaften();
const { sparten, fetchSparten } = useSparten();

const {
  validFileTypes,
  validFiles,
  invalidFiles,
  fileNames,
  hasMultipleImageFiles,
  exceedsImageCombinationLimit,
  errorMessages,
  setFiles,
  setFileName,
  upload,
  remove,
} = useDocumentUpload({ compressImages: true });
const { documentTypes, fetchDocumentTypes } = useDocumentTypes();

const documentTypeOptions = toOptions(documentTypes, 'name', 'id');
const companyOptions = toOptions(gesellschaften, 'name', 'id');

watch(() => metaData.contract_id, (id) => {
  if (contracts.value.length <= 0) return;
  const contract = contracts.value.find((c) => c.id === id);
  metaData.insurance_company_id = contract?.company.id ?? null;
  metaData.product_combo_id = contract?.product.id ?? null;
});

watch(
  () => metaData.combine,
  () => validFiles.value.forEach((file, index) => {
    if (file.type.startsWith('image/')) {
      setFileName(file.name, index);
    }
  }),
);

watch([hasMultipleImageFiles, exceedsImageCombinationLimit], () => {
  metaData.combine = hasMultipleImageFiles.value && !exceedsImageCombinationLimit.value;
});

const clear = () => {
  invalidFiles.value = [];
  errorMessages.value = [];
  validFiles.value = [];
  hasError.value = false;
  Object.assign(metaData, initMetaData);
};

const uploadFiles = async () => {
  if (!validFiles.value) return;
  hasError.value = false;
  loading.value = true;
  try {
    await upload(metaData);
    await fetchDocuments();
    clear();
  } catch (error) {
    hasError.value = true;
    captureException(error);
  } finally {
    loading.value = false;
  }
};

const uploadHandler = async () => {
  await uploadFiles();
  trackEvent({ category: TrackEventCategory.DOCUMENT_UPLOAD, action: 'Hochladen', name: 'click' });
};

fetchGesellschaften();
fetchSparten();
fetchContracts();
fetchDocumentTypes();
</script>

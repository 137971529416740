import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { Contract, ContractOption } from '@/api/contracts/types';
import { useContractStore } from '@/contracts/stores/contractStore';

const buildContractOption = (contract: Contract): ContractOption => {
  const { id, product, number, status } = contract;
  const label: string[] = [];
  if (product?.name) label.push(product.name);
  if (number !== null) label.push(`(Vertrags-Nr.: ${number})`);
  if (status.id !== 1) label.push('(inaktiv)');
  return {
    label: label.join(' '),
    value: id,
    inactive: status.id !== 1,
    contractStatus: status.id,
    number,
  };
};

const contractOptions = computed(() => (
  useContractStore().contracts
    .map((contract) => buildContractOption(contract))
    .sort((a, b) => a.contractStatus - b.contractStatus)
));

export const useContractOptions = (): {
  contractOptions: ComputedRef<ContractOption[]>,
} => ({
  contractOptions,
});

// from https://github.com/jsdf/deep-freeze/blob/master/index.js

function deepFreeze<T>(object: T): Readonly<T> {
  Object.freeze(object);

  const oIsFunction = typeof object === 'function';
  const hasOwnProp = Object.prototype.hasOwnProperty;

  Object.getOwnPropertyNames(object).forEach((prop) => {
    if (hasOwnProp.call(object, prop)
      && (oIsFunction ? prop !== 'caller' && prop !== 'callee' && prop !== 'arguments' : true)
      && object[prop] !== null
      && (typeof object[prop] === 'object' || typeof object[prop] === 'function')
      && !Object.isFrozen(object[prop])) {
      deepFreeze(object[prop]);
    }
  });

  return object;
}

export { deepFreeze };
